import ModalSearchSavingAccount from "../../../../components/modal-search-saving-account";

export default {
  name: "PaymentTVirtualAccountPpob",
  components: {
    ModalSearchSavingAccount,
  },
  data() {
    return {
      identity: {
        virtualAccountId: "",
      },
      dataForm: {
        printPPob: "",
        virtualAccountNumber: "",
        accountName: "",
        officeName: "",
        description: "",
        documentProofNumber: "",
        transactionDate: "",
        paymentTypeId: "",
        billedAccount: "",
        billedName: "",
        billedDescription: "",
        nominalAdmin: 0,
        nominal: 0,
      },
      options: {
        paymentType: [],
      },
      property: {
        animation: {
          paymentVirtualAccountPpob: {
            isLoading: false,
          },
          cetakSlip: {
            isLoading: false,
          },
        },
        modal: {
          showModalFindSavingAccount: false,
          showModalPrint: false,
        },
      },
    };
  },
  methods: {
    async cetakSlipTrx() {
      this.property.animation.cetakSlip.isLoading = true;

      let pdf = new jsPDF("landscape", "mm", [297, 210]);

      setTimeout(() => {
        html2canvas(document.querySelector("#table-slip-rak"), {
          imageTimeout: 2000,
          useCORS: true,
        }).then((canvas) => {
          document.getElementById("table-slip-rak").appendChild(canvas);
          let img = canvas.toDataURL("image/png");
          pdf.addImage(img, "PNG", 21, 9);
          pdf.autoPrint();

          const hiddFrame = document.createElement("iframe");
          hiddFrame.style.position = "fixed";
          hiddFrame.style.width = "1px";
          hiddFrame.style.height = "1px";
          hiddFrame.style.opacity = "0.01";
          const isSafari = /^((?!chrome|android).)*safari/i.test(
            window.navigator.userAgent
          );
          if (isSafari) {
            // fallback in safari
            hiddFrame.onload = () => {
              try {
                hiddFrame.contentWindow.document.execCommand(
                  "print",
                  false,
                  null
                );
              } catch (e) {
                hiddFrame.contentWindow.print();
              }
            };
          }
          hiddFrame.src = pdf.output("bloburl");
          document.body.appendChild(hiddFrame);
          document.getElementById("table-slip-rak").innerHTML = "";
          this.property.animation.cetakSlip.isLoading = false;
          this.property.modal.showModalPrint = false;
        });
      }, 200);
    },

    getDateFormat(valDate) {
      var date = new Date(valDate);
      var dd = date.getDate();
      var mm = date.getMonth() + 1;
      var y = date.getFullYear();
      var time = date.toLocaleTimeString("en-GB");

      dd = dd.toString().length == 1 ? "0" + dd : dd;
      mm = mm.toString().length == 1 ? "0" + mm : mm;

      var formattedDate = dd + "-" + mm + "-" + y + " " + time;
      return formattedDate;
    },
    async paymentVirtualAccountPpob() {
      const payload = {
        documentProofNumber: this.dataForm.documentProofNumber,
        paymentTypeId: this.dataForm.paymentTypeId,
        billedAccount: this.dataForm.billedAccount,
        billedName: this.dataForm.billedName,
        billedDescription: this.dataForm.billedDescription,
        nominalAdmin: this.dataForm.nominalAdmin,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Simpan Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.paymentVirtualAccountPpob.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA",
                endPoint: "transaction",
                reqUrl:
                  "virtual-account-ppob/pay-bill/" +
                  this.identity.virtualAccountId,
                payload: payload,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  const toastBuefy = this.$buefy.toast.open(
                    this.$NotificationUtils.success
                  );
                  toastBuefy.$on("close", () => {
                    this.showModalPrint(resp);
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            } finally {
              setTimeout(() => {
                this.property.animation.paymentVirtualAccountPpob.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
    selectSavingAccount(props) {
      this.dataForm.billedAccount = props.row.accountNumber;
      this.dataForm.billedName = props.row.qqName
        ? `${props.row.cifIdName} ${props.row.qqCode} ${props.row.qqName}`
        : props.row.cifIdName;
    },
    getIdentityFromSession() {
      const getTokenFromSession = sessionStorage.getItem(
        "VIRTUAL_ACCOUNT_NUMBER_FOR_PPOB_PAYMENT"
      );
      if (!getTokenFromSession) {
        this.$buefy.toast.open(this.$NotificationUtils.idNotFound);
        this.routeToPageListVirtualAccountPpob();
        return;
      }
      this.identity.virtualAccountId = getTokenFromSession;
    },
    routeToPageListVirtualAccountPpob() {
      this.$router.push("list");
    },
    async getVirtualAccountPpobById() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        this.routeToPageListVirtualAccountPpob();
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "transaction",
            reqUrl: "virtual-account-ppob/" + this.identity.virtualAccountId,
          });
          console.log(resp);
          if (resp.data.code === "SUCCESS") {
            this.dataForm.virtualAccountNumber =
              resp.data.data.virtualAccountNumber;
            this.dataForm.officeName = resp.data.data.officeName;
            this.dataForm.description = resp.data.data.description;
            this.dataForm.nominal = resp.data.data.nominal;
            this.dataForm.transactionDate = resp.data.data.transactionDate;
            this.dataForm.accountName = resp.data.data.cifIdName;
            this.autoDescription();
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
              onConfirm: () => this.routeToPageListVirtualAccountPpob(),
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
            onConfirm: () => this.routeToPageListVirtualAccountPpob(),
          });
        }
      }
    },
    showModalPrint(resp) {
      this.dataForm.printPPob = resp.data.data;
      this.property.modal.showModalPrint = true;
    },
    async getReferenceVirtualAccountPpob() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "ppob-payment-type",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = index.name;
            const value = index.paymentTypeId;
            this.options.paymentType.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async autoDescription() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "parameter",
          reqUrl: "transaction-code/transaction-code/" + "9011",
        });
        if (resp.data.code === "SUCCESS") {
          this.dataForm.billedDescription = `${resp.data.data.transactionName} no ${this.dataForm.virtualAccountNumber}`;
        }
      } catch {}
    },
  },
  mounted() {
    this.getIdentityFromSession();
    this.getVirtualAccountPpobById();
    this.getReferenceVirtualAccountPpob();
  },
};
